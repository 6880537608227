import Router from 'next/router';
import { contains, omit } from 'ramda';
import { isObject, pick } from 'remeda';
import { ISupplier, ISupplier_Type } from '@bridebook/models/source/models/Suppliers.types';
import { AdSearchResults } from '@bridebook/toolbox/src/advertising/types';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { countriesWithForcedCuratedListSearch } from '@bridebook/toolbox/src/i18n/features';
import type { Aggregation } from '@bridebook/toolbox/src/search-suppliers/process-aggregations';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import type { SearchFacetsResult } from '@bridebook/toolbox/src/seo-facets';
import { IUISupplier, Slug } from '@bridebook/toolbox/src/types';
import {
  AutocompletePrediction,
  ISearchPageQuery,
  ISearchQuery,
  SortOptionValue,
} from 'app-shared/lib/search/types';
import { AutocompletePredictionsService } from 'app-shared/lib/search/utils/autocomplete-predictions-service';
import { isCustomAutocompletePrediction } from 'app-shared/lib/search/utils/custom-autocomplete-predictions';
import { GooglePlacesService } from 'app-shared/lib/search/utils/google-places-service';
import { SearchRequestBody, SearchResponse } from 'pages/api/search';
import { SearchCountRequestBody, SearchCountResponse } from 'pages/api/search/count';
import { TQuizId } from 'components/quiz/types';
import { getIsGlobalSearchEnabled } from 'lib/ab-tests/tests/global/LIVE-19547_EnableGlobalSearch';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { getWeddingL10n } from 'lib/i18n/selectors';
import { TQuizNavigationMethod } from 'lib/quiz/types';
import { navigateToSearchResults } from 'lib/search-landing/actions';
import { IFetchSearchSuccessAnalytics, SearchActionTypes } from 'lib/search/action-types';
import { ISearchSuccessAnalyticsCustomData, presetsSection } from 'lib/search/analytics';
import { allowedGroupFilters } from 'lib/search/filter-sections';
import {
  getFilters,
  getIsGlobalCountrySearch,
  getMapMovedCoords,
  getPreset,
  getSupplierById,
} from 'lib/search/selectors';
import {
  IElasticSuggestion,
  IFetchSearchPromiseArgs,
  IFetchSearchPromiseResult,
  IMapMovedCoords,
  ISearchSource,
  ISearchState,
  ISnippet,
  SearchResponseFields,
  SupplierNavigateTarget,
  TFiltersTab,
  TSearchLocation,
  TSearchLocationInitialized,
  TSearchResponse,
  UpdateSearchFilters,
} from 'lib/search/types';
import formatArea from 'lib/search/utils/format-prediction-area';
import { getCountryCodeFromArea } from 'lib/search/utils/get-country-code-from-area';
import { getSearchPaginationUrl } from 'lib/search/utils/get-search-pagination-url';
import manageSearchFilterAccordionList from 'lib/search/utils/manage-search-filter-accordion-list';
import { IPerformedSearchStorage } from 'lib/storage-manager/basic/performed-search';
import { Action, IApplicationState, IDeps, ILatLongBounds, IQuery, IUrl } from 'lib/types';
import { noopAction, prepareObjForElastic } from 'lib/utils';
import { getWeddingCountryBounds } from 'lib/weddings/selectors';
import { TQuizNameAnalytics } from './analytics-constants';
import { getArea, getFilterCount } from './utils';
import createSearchQuery from './utils/create-search-query';

export const fetchSearchSuccess = (
  payload: {
    results: IUISupplier[];
    fields: SearchResponseFields;
    totalResults: number;
    filters: Record<string, Record<string, string | boolean>>;
    user?: boolean;
    distanceSortDisabled: boolean;
    facetsContent: SearchFacetsResult | null;
    isNoResultsSearch?: boolean;
    aggregations?: Aggregation[];
  },
  track: boolean = true,
  forceReplace: boolean = false,
) => ({
  type: SearchActionTypes.FETCH_SEARCH_SUCCESS,
  payload: { ...payload, forceReplace, track },
});

export const fetchedSearchDataAnalytics = (
  searchResponse: TSearchResponse,
  customData?: ISearchSuccessAnalyticsCustomData,
): IFetchSearchSuccessAnalytics => ({
  type: SearchActionTypes.FETCH_SEARCH_SUCCESS_ANALYTICS,
  payload: {
    customData,
    searchResponse,
  },
});

export const toggleFilters = () => ({
  type: SearchActionTypes.TOGGLE_FILTERS,
});

export const toggleFiltersAnalytics = (method: string) => ({
  type: SearchActionTypes.TOGGLED_SEARCH_FILTERS_ANALYTICS,
  payload: { method },
});

export const fetchSearchPromise = async (args: IFetchSearchPromiseArgs, signal?: AbortSignal) =>
  authenticatedPOST<SearchRequestBody, SearchResponse>(ApiEndpoint.search.main, {
    body: createSearchQuery(args),
    signal,
  });

export const fetchSearchCountPromise = async (
  args: IFetchSearchPromiseArgs,
  signal?: AbortSignal,
) =>
  authenticatedPOST<SearchCountRequestBody, SearchCountResponse>(ApiEndpoint.search.count, {
    body: createSearchQuery(args),
    signal,
  });

export const fetchSearchPagesPromise = async (
  args: Omit<IFetchSearchPromiseArgs, 'page'>,
  pages: number[],
): Promise<IFetchSearchPromiseResult> => {
  if (pages.length === 0) {
    throw new Error('Page argument is required');
  }

  // fetch all pages
  const responses = await Promise.all(
    pages.map((page) => fetchSearchPromise({ ...args, page } as IFetchSearchPromiseArgs)),
  );

  // accumulate it into one response
  return responses.reduce((previousValue, currentValue) => {
    if (previousValue.results) {
      previousValue = {
        ...currentValue,
        results: [...previousValue.results, ...currentValue.results],
      };
    } else {
      previousValue = currentValue;
    }
    return previousValue;
  });
};
export const clickedSearchMyselfAnalytics = (searchSupplierCategory: string) => ({
  type: SearchActionTypes.CLICKED_SEARCH_MYSELF,
  payload: {
    searchSupplierCategory,
  },
});

export const fetchSearchSuggestions = (
  searchTerm: string,
  slug: string | null = '',
  size: number = 5,
  lockCategory: boolean = false,
) => ({
  type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS,
  payload: {
    searchTerm,
    slug,
    size,
    lockCategory,
  },
});

export const suggestionSuccess = (payload: IElasticSuggestion[]) => ({
  type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  payload,
});

export function updateSearchCategory(type: Slug, skipRequest?: boolean) {
  return {
    type: SearchActionTypes.UPDATE_SEARCH_CATEGORY,
    payload: {
      type,
      skipRequest,
    },
  };
}

export const setSearchbarFormField = (payload: string) => ({
  type: SearchActionTypes.SET_SEARCHBAR_FORM_FIELD,
  payload,
});

export const triggerClickedSuppliersPageAnalytics = (
  selectedTab: 'findSuppliers' | 'yourFavourites' | 'booked',
) => ({
  type: SearchActionTypes.TAB_NAVIGATION_SUPPLIERS,
  payload: {
    selectedTab,
  },
});

export function resetSearchFilters(withAnalytics = true, updateInitial = false) {
  return ({ dispatch }: IDeps): Action<{ updateInitial: boolean }> => {
    if (withAnalytics) {
      dispatch({ type: SearchActionTypes.RESET_SEARCH_FILTERS_ANALYTICS });
    }

    return {
      type: SearchActionTypes.RESET_SEARCH_FILTERS,
      payload: { updateInitial },
    };
  };
}

/**
 * Resets all filters and other search conditions (map coordinates, collection id etc.)
 */
export function resetSearchConditions() {
  return {
    type: SearchActionTypes.RESET_SEARCH_CONDITIONS,
  };
}

export function searchClearAll() {
  return {
    type: SearchActionTypes.CLEAR_ALL_SEARCH,
  };
}

export function fetchSearchStart(searchParams: ISearchState['request']) {
  return {
    type: SearchActionTypes.FETCH_SEARCH_START,
    payload: searchParams,
  };
}

const updateSearchFiltersAnalytics = (payload: UpdateSearchFilters) => ({
  type: SearchActionTypes.UPDATE_SEARCH_FILTERS_ANALYTICS,
  payload,
});

export const updateSearchFilters =
  (payload: UpdateSearchFilters | UpdateSearchFilters[]) =>
  ({ dispatch }: IDeps) => {
    const actionPayload = Array.isArray(payload) ? payload : [payload];
    actionPayload
      .filter(({ skipAnalytics }) => !skipAnalytics)
      .forEach((payload) => {
        dispatch(updateSearchFiltersAnalytics(payload));
      });

    return {
      type: SearchActionTypes.UPDATE_SEARCH_FILTERS,
      payload,
    };
  };

export const updateAllSearchFiltersAnalytics =
  (location: string) =>
  ({ getState, dispatch }: IDeps) => {
    const state = getState();
    const filtersState = getFilters(state);
    const groupFiltersInUse = pick(filtersState, allowedGroupFilters);
    const miscFiltersInUse = filtersState.misc as unknown;

    Object.entries(groupFiltersInUse).forEach(([name, filters]) => {
      dispatch(updateSearchFiltersAnalytics({ name, value: filters, location }));
    });

    isObject(miscFiltersInUse) &&
      Object.entries(miscFiltersInUse).forEach(([key, value]) => {
        dispatch(updateSearchFiltersAnalytics({ name: 'misc', value: { [key]: value }, location }));
      });

    return noopAction();
  };

export function updateSearchUrl({
  location,
  page,
  sortBy,
  queryParams: queryParamsArg,
}: {
  location: IUrl<ISearchPageQuery | IQuery>;
  page?: number;
  sortBy?: string;
  queryParams?: { [key: string]: string | number | boolean | undefined };
}) {
  return ({ getState }: IDeps) => {
    const state = getState();
    const filtersState = getFilters(state);
    const { zoom: mapMovedZoom, ne: mapMovedNE, sw: mapMovedSW } = getMapMovedCoords(state);
    const preset = getPreset(state);

    const searchOnMoveData =
      mapMovedZoom && mapMovedNE && mapMovedSW
        ? {
            searchOnMove: true,
            zoom: mapMovedZoom,
            nelat: mapMovedNE.lat,
            nelon: mapMovedNE.lng,
            swlat: mapMovedSW.lat,
            swlon: mapMovedSW.lng,
          }
        : undefined;

    const { distanceSortDisabled, request } = state.search;
    const market = gazetteer.getMarketByCountry(state.weddings.profile.l10n.country);
    const { placeId, area, searchParams, query, slug } = location;

    const urls = getSearchPaginationUrl({
      page,
      sortBy,
      distanceSortDisabled,
      sort: request.sort,
      filtersState,
      searchOnMoveData,
      preset,
      market,
      locationArea: area,
      searchParams: searchParams || query?.searchParams,
      locationSlug: slug || query?.slug,
      placeId: placeId || query?.placeId,
    });

    const isGlobalSearch = market.country !== request.market.country;

    const [url, urlSearchParams] = urls.as.split('?');
    const queryParams = new URLSearchParams(urlSearchParams || '');

    const queryParamsToKeep =
      countriesWithForcedCuratedListSearch.includes(market.country) && !isGlobalSearch
        ? { isCurated: !!filtersState?.destinationWeddingsSection?.isCurated }
        : {};

    const combinedQueryParams = { ...queryParamsToKeep, ...queryParamsArg };

    Object.entries(combinedQueryParams).forEach(([key, value]) => {
      typeof value !== 'undefined' && queryParams.set(key, value.toString());
    });
    const uniqueParams = new Set(queryParams);
    const newQueryParams = [...uniqueParams]
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    urls.as = `${url}?${newQueryParams}`;
    /*
     * If this update also changes page,
     * like for logged out search pagination, then do push, otherwise replace
     * */
    if (page) {
      Router.push(urls.as);
    } else {
      Router.replace(urls.as);
    }

    return {
      type: SearchActionTypes.UPDATE_SEARCH_URL,
    };
  };
}

export function loadMore({ location, page }: { location: { query: ISearchQuery }; page: number }) {
  return ({ dispatch }: IDeps) => {
    dispatch(updateSearchUrl({ location, page }));
    dispatch({
      type: SearchActionTypes.SEARCH_LOAD_MORE_ANALYTICS,
      payload: { pageNumber: page },
    });
    return {
      type: SearchActionTypes.SEARCH_LOAD_MORE,
    };
  };
}

export const updateFiltersCount =
  () =>
  ({ getState }: IDeps) => {
    const filtersState = getFilters(getState());
    return {
      type: SearchActionTypes.UPDATE_FILTERS_COUNT,
      payload: { count: getFilterCount(filtersState) },
    };
  };

export const toggleSearchFilterAccordion =
  (name: string, opened: boolean = false) =>
  ({ getState }: IDeps) => {
    const { filterAccordions } = getState().search;
    const open = opened || !contains(name, filterAccordions);

    const updatedAccordionList = manageSearchFilterAccordionList({
      name,
      open,
      accordionList: filterAccordions,
    });

    return {
      type: SearchActionTypes.TOGGLE_SEARCH_FILTER_ACCORDION,
      payload: updatedAccordionList,
    };
  };

export const onSortChangeAnalytics = (sortBy: string) => ({
  type: SearchActionTypes.SEARCH_SORT_CHANGE_ANALYTICS,
  payload: { sortBy },
});

export const setLastRowPosition = (row: number) => ({
  type: SearchActionTypes.SEARCH_SET_LAST_ROW_POSITION,
  payload: row,
});

export const setSearchSource = (searchSource: ISearchSource | null) => ({
  type: SearchActionTypes.SET_SEARCH_SOURCE,
  payload: { searchSource },
});

export const setPerformedSearch = (performed: IPerformedSearchStorage) => ({
  type: SearchActionTypes.SET_PERFORMED_SEARCH,
  payload: { performed },
});

/** SEARCH MAP ACTIONS */
export const clickMapMarker =
  (supplierId: string, withAnalytics = true) =>
  ({ dispatch, getState }: IDeps) => {
    if (withAnalytics) {
      const supplier = getSupplierById(getState(), supplierId);
      dispatch(mapMarkerClickedAnalytics(supplier));
    }

    return {
      type: SearchActionTypes.MAP_MARKER_CLICKED,
      payload: supplierId,
    };
  };

export const highlightMapMarker = (supplierId?: string) => ({
  type: SearchActionTypes.MAP_MARKER_HIGHLIGHTED,
  payload: supplierId,
});

export const mapMarkerClickedAnalytics = (supplier: ISupplier | IUISupplier) => ({
  type: SearchActionTypes.MAP_MARKER_CLICKED_ANALYTICS,
  payload: supplier,
});

export const viewedInjectedSnippetAnalytics = (snippet: ISnippet, snippetType: string) => ({
  type: SearchActionTypes.VIEWED_INJECTED_SNIPPET,
  payload: { snippet, snippetType },
});

export const clearHighlightedMapMarker = () => ({
  type: SearchActionTypes.MAP_CLEAR_HIGHLIGHTED_MARKER,
});

export const clearClickedMapMarker = () => ({
  type: SearchActionTypes.MAP_CLEAR_CLICKED_MARKER,
});

export const mapMoved = (params: IMapMovedCoords) => ({
  type: SearchActionTypes.MAP_MOVED,
  payload: params,
});

export const toggleMapView = (showMapView: boolean) => ({
  type: SearchActionTypes.TOGGLE_MAP_VIEW,
  payload: { showMapView },
});

export const clickedPresetAnalytics = (preset: string) =>
  updateSearchFiltersAnalytics({
    name: presetsSection,
    value: preset ? { [preset]: true } : {},
    location: 'searchPagePresets',
  });

const getPredictionByText = async (
  autocompletePredictionsService: AutocompletePredictionsService,
  searchText: string,
  {
    countryCode,
    countryLatLongBounds,
  }: { countryCode: CountryCodes | undefined; countryLatLongBounds: ILatLongBounds },
) => {
  const { predictions } = await autocompletePredictionsService.getAutocompletePredictions(
    searchText,
    { countryCode, bounds: countryLatLongBounds, category: '' },
  );
  const finalPrediction = predictions[0];
  if (!finalPrediction) return null;
  return finalPrediction;
};

const getAreaFromPrediction = async (
  googlePlacesService: GooglePlacesService,
  prediction: AutocompletePrediction,
) => {
  if (isCustomAutocompletePrediction(prediction)) {
    return prediction.area;
  }
  const place = await googlePlacesService.getPlaceDetails([prediction]);
  return getArea(place);
};

const getSearchLocation: (
  googlePlacesService: GooglePlacesService,
  autocompletePredictionsService: AutocompletePredictionsService,
  request: TSetLocationRequest,
  state: IApplicationState,
) => Promise<TSearchLocationInitialized | null> = async (
  googlePlacesService,
  autocompletePredictionsService,
  request,
  state,
) => {
  if (state.search.searchLocation.status === 'uninitialized') return null;

  const countryLatLongBounds = getWeddingCountryBounds(state);
  const weddingL10n = getWeddingL10n(state);
  const market = gazetteer.getMarketByCountry(weddingL10n.country, CountryCodes.GB);
  const isMarketWithoutDomesticDirectories = !market.hasAnyDirectory;
  const isGlobalSearchEnabled = getIsGlobalSearchEnabled(state);
  const countryCode =
    isGlobalSearchEnabled || isMarketWithoutDomesticDirectories ? undefined : market.country;

  try {
    let prediction;
    if (request.type === 'autocomplete') {
      prediction = await getPredictionByText(autocompletePredictionsService, request.text, {
        countryCode,
        countryLatLongBounds,
      });
    } else if (request.type === 'prediction') {
      prediction = request.prediction;
    } else if (request.type === 'current') {
      const currentSearchType = state.search.searchLocation.draft.searchType;
      if (currentSearchType === 'prediction') {
        return {
          ...state.search.searchLocation,
        };
      } else {
        prediction = await getPredictionByText(
          autocompletePredictionsService,
          state.search.searchLocation.draft.searchText,
          { countryCode, countryLatLongBounds },
        );
      }
    } else {
      throw new Error('search location request not supported');
    }

    if (!prediction) {
      return null;
    }
    const area = await getAreaFromPrediction(googlePlacesService, prediction);
    const label = formatArea(area);
    return {
      status: 'initialized',
      selected: {
        area,
        placeId: prediction.place_id,
        searchText: label,
        searchType: 'prediction',
        countryCode: getCountryCodeFromArea(area),
      },
      draft: {
        searchText: label,
        searchType: 'prediction',
      },
    };
  } catch (e) {
    SentryMinimal().captureException(e, {
      tags: {
        source: 'getSearchLocation',
      },
    });
    throw e;
  }
};

type TSetLocationRequest =
  | {
      type: 'autocomplete';
      text: string;
    }
  | {
      type: 'prediction';
      prediction: AutocompletePrediction;
    }
  | {
      type: 'current';
    };

const determineUserProvidedText = (
  stateSearchLocation: TSearchLocation,
  newSearchLocation: TSearchLocation,
) => {
  if (
    stateSearchLocation.status === 'initialized' &&
    stateSearchLocation.draft.searchType === 'autocomplete'
  ) {
    if (
      newSearchLocation.status === 'initialized' &&
      newSearchLocation.draft.searchType === 'prediction'
    ) {
      return stateSearchLocation.draft.searchText;
    }
  }
  return undefined;
};

export const setSearchLocation =
  (request: TSetLocationRequest) =>
  ({ getState, dispatch, googlePlacesService, autocompletePredictionsService }: IDeps) => {
    const asyncAction = async () => {
      dispatch({
        type: SearchActionTypes.SET_SEARCH_LOCATION_START,
      });
      const state = getState();
      const previousSearchLocation = state.search.searchLocation;

      const newSearchLocation = await getSearchLocation(
        googlePlacesService,
        autocompletePredictionsService,
        request,
        state,
      );
      if (newSearchLocation) {
        const notAutocompletedSearchTerm =
          request.type === 'autocomplete'
            ? request.text
            : determineUserProvidedText(state.search.searchLocation, newSearchLocation);
        dispatch(setSearchLocationEnd(newSearchLocation));
        if (previousSearchLocation.status === 'initialized') {
          const category = state.search.request.slug;
          const newSearchTerm = newSearchLocation.draft.searchText;
          const previousSearchTerm = previousSearchLocation.draft.searchText;
          if (newSearchTerm !== previousSearchTerm) {
            dispatch(
              changedSearchLocationAnalyticsDebounced({
                notAutocompletedSearchTerm,
                newSearchTerm,
                previousSearchTerm,
                searchSupplierCategory: category,
                searchCountryCode: newSearchLocation.selected.countryCode,
                searchCountryName: newSearchLocation.selected.countryCode
                  ? Gazetteer.getCountryName(newSearchLocation.selected.countryCode as CountryCodes)
                  : undefined,
              }),
            );
          }
        }
      }
    };
    asyncAction();

    return noopAction();
  };

export const searchBySearchLocation =
  (locationRequest: TSetLocationRequest, category: 'selected' | 'actualCategory' = 'selected') =>
  ({ getState, dispatch, googlePlacesService, autocompletePredictionsService }: IDeps) => {
    const asyncAction = async () => {
      const state = getState();
      if (
        state.search.searchLocation.status === 'uninitialized' ||
        !state.search.searchLocation.draft.searchText?.trim()
      )
        return;

      dispatch({
        type: SearchActionTypes.SEARCH_BY_SEARCH_LOCATION_START,
      });

      const previousSearchLocation = state.search.searchLocation;

      const newSearchLocation = await getSearchLocation(
        googlePlacesService,
        autocompletePredictionsService,
        locationRequest,
        state,
      );

      const marketChanged =
        state.search.results.status === 'loaded' &&
        newSearchLocation?.selected.countryCode !== state.search.results.fields.countryCode;

      const categoryChanged =
        state.search.results.status === 'loaded' &&
        state.search.results.fields.type !== state.search.request.slug;

      if (newSearchLocation) {
        dispatch(setSearchLocationEnd(newSearchLocation));
        const navigateToCategory =
          (category === 'selected'
            ? state.search.request.slug
            : state.search.results.status === 'loaded' && state.search.request.slug) || 'venue';

        const shouldClearFilters = marketChanged || categoryChanged;
        const currentFilters = shouldClearFilters ? {} : getFilters(state);

        const area = newSearchLocation.selected.area;
        const placeId = newSearchLocation.selected.placeId;

        const isGlobalCountrySearch = getIsGlobalCountrySearch(area)(state);
        // curated filter is active by default only for venue country global search
        const isCuratedFilterOn = navigateToCategory === 'venue' && isGlobalCountrySearch;

        const preparedFilters = prepareObjForElastic({
          ...omit(['destinationWeddingsSection'], currentFilters),
          ...(isCuratedFilterOn && { destinationWeddingsSection: { isCurated: true } }),
        });

        dispatch(
          navigateToSearchResults({
            slug: navigateToCategory,
            area,
            placeId,
            filters: preparedFilters,
          }),
        );
        dispatch({
          type: SearchActionTypes.SEARCH_BY_SEARCH_LOCATION_END,
          area,
          placeId,
          slug: navigateToCategory,
        });

        if (state.search.searchLocation.status === 'initialized') {
          const notAutocompletedSearchTerm =
            locationRequest.type === 'autocomplete'
              ? locationRequest.text
              : determineUserProvidedText(previousSearchLocation, newSearchLocation);
          const category = state.search.request.slug;
          const newSearchTerm = newSearchLocation.draft.searchText;
          const previousSearchTerm = previousSearchLocation.draft.searchText;
          if (newSearchTerm !== previousSearchTerm) {
            dispatch(
              changedSearchLocationAnalyticsDebounced({
                notAutocompletedSearchTerm,
                newSearchTerm,
                previousSearchTerm,
                searchSupplierCategory: category,
                searchCountryCode: newSearchLocation.selected.countryCode,
                searchCountryName: newSearchLocation.selected.countryCode
                  ? Gazetteer.getCountryName(newSearchLocation.selected.countryCode as CountryCodes)
                  : undefined,
              }),
            );
          }
        }
      } else {
        dispatch(setSearchLocationUnknown(true));
      }
    };
    asyncAction();

    return noopAction();
  };

export const setSearchLocationEnd = (searchLocation: TSearchLocationInitialized) => ({
  type: SearchActionTypes.SET_SEARCH_LOCATION_END,
  payload: searchLocation,
});

export const setSearchLocationAutocompleteText = (searchText: string) => ({
  type: SearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_TEXT,
  payload: {
    searchText,
  },
});

export const setPopularVenueTypes = (payload: ISearchState['popularVenueTypes']) => ({
  type: SearchActionTypes.SET_POPULAR_VENUE_TYPES,
  payload,
});

export const startedQuizAnalytics = (quizName: TQuizNameAnalytics, location: string) => ({
  type: SearchActionTypes.STARTED_QUIZ,
  payload: {
    quizName,
    location,
  },
});

export const noResultsQuizAnalytics = (
  quizName: TQuizNameAnalytics,
  searchSupplierCategory: Slug,
  location: string,
) => ({
  type: SearchActionTypes.NO_RESULTS_QUIZ,
  payload: {
    quizName,
    searchSupplierCategory,
    location,
  },
});

export const choseQuizFiltersAnalytics = (
  quizName: string,
  quizStepIndex: number,
  quizStepContent: string,
  filters: Record<string, Record<string, boolean | string | number>>,
  category?: string,
) => ({
  type: SearchActionTypes.CHOSE_QUIZ_FILTERS,
  payload: {
    quizName,
    quizStepNumber: quizStepIndex + 1,
    quizStepContent,
    filters,
    category,
  },
});

export const loadedQuizResultsAnalytics = (payload: {
  searchActiveFilers: Record<string, boolean | string | number>;
  searchSource: TQuizNameAnalytics;
  searchView: string;
  searchTerm: string;
  quizResultsLoaded: number;
}) => ({
  type: SearchActionTypes.LOADED_QUIZ_RESULTS_ANALYTICS,
  payload,
});

export const wentToNextQuizStepAnalytics = (
  quizName: string,
  quizStepIndex: number,
  quizStepContent: string,
  filters: Record<string, unknown>,
  navigationMethod: TQuizNavigationMethod,
) => ({
  type: SearchActionTypes.WENT_TO_NEXT_QUIZ_STEP,
  payload: {
    quizName,
    quizStepNumber: quizStepIndex + 1,
    quizStepContent,
    navigationMethod,
    filters,
  },
});

export const exitedQuizAnalytics = (
  quizName: string,
  quizStepNumber: number,
  quizStepContent: string,
) => ({
  type: SearchActionTypes.EXITED_QUIZ,
  payload: {
    quizName,
    quizStepNumber,
    quizStepContent,
  },
});

export const loadedSearchPreferencePageAnalytics = (searchSupplierCategory: string) => ({
  type: SearchActionTypes.LOADED_SEARCH_PREFERENCE_PAGE,
  payload: {
    searchSupplierCategory,
  },
});

export const clickedFindMyMatchAnalytics = (searchSupplierCategory: string) => ({
  type: SearchActionTypes.CLICKED_FIND_MY_MATCH,
  payload: {
    searchSupplierCategory,
  },
});

export const changedCategorySelectionAnalytics = (selectedSupplierCategory: string[]) => ({
  type: SearchActionTypes.CHANGED_CATEGORY_SELECTION,
  payload: {
    selectedSupplierCategory,
  },
});

export const clickedShowMeSuppliers = () => ({
  type: SearchActionTypes.CLICKED_SHOW_ME_SUPPLIERS,
});

export const userWentToAPreviousQuizPageAnalytics = (
  quizName: string,
  quizStepNumber: number,
  quizStepContent: string,
) => ({
  type: SearchActionTypes.USER_WENT_TO_A_PREVIOUS_QUIZ_PAGE,
  payload: {
    quizName,
    quizStepNumber,
    quizStepContent,
  },
});

export const loadedRecommendedSupplierPageAnalytics = () => ({
  type: SearchActionTypes.LOADED_RECOMMENDED_SUPPLIER_PAGE,
});

export const changedSearchCategoryAnalytics = (payload: {
  newSearchSupplierCategory: Slug;
  previousSearchSupplierCategory?: Slug | '';
}) => ({
  type: SearchActionTypes.CHANGED_SEARCH_CATEGORY_ANALYTICS,
  payload,
});

export interface IChangedSearchLocationAnalyticsPayload {
  notAutocompletedSearchTerm?: string;
  searchSupplierCategory: Slug;
  previousSearchTerm: string;
  newSearchTerm: string;
  searchLocationChangeQuiz?: TQuizId;
  searchLocationChangeSource?: string;
  searchCountryCode?: string;
  searchCountryName?: string;
}

export const changedSearchLocationAnalytics = (
  payload: IChangedSearchLocationAnalyticsPayload,
) => ({
  type: SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS,
  payload,
});

export const changedSearchLocationAnalyticsDebounced = (
  payload: IChangedSearchLocationAnalyticsPayload,
) => ({
  type: SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS_DEBOUNCED,
  payload,
});

export const clickedOnFilteredSearchTileAnalytics = (payload: {
  searchTileFilterCategory: 'venueType' | 'supplierType';
  searchTileFilterValue: string;
  location: string;
}) => ({
  type: SearchActionTypes.CLICKED_ON_FILTERED_SEARCH_TILE_ANALYTICS,
  payload,
});

export const setSessionSuppliersRedirectTarget = (payload: SupplierNavigateTarget) => ({
  type: SearchActionTypes.SET_SESSION_SUPPLIERS_REDIRECT_TARGET,
  payload,
});

export const viewedBannerAnalytics = (bannerText: string, supplierCategory: ISupplier_Type) => ({
  type: SearchActionTypes.VIEWED_BANNER_ANALYTICS,
  payload: {
    bannerText,
    supplierCategory,
  },
});

export const clickedBannerAnalytics = (supplierCategory: ISupplier_Type) => ({
  type: SearchActionTypes.CLICKED_BANNER_ANALYTICS,
  payload: {
    supplierCategory,
  },
});

export const setSearchLocationUnknown = (isSearchLocationUnknown: boolean) => ({
  type: SearchActionTypes.SET_SEARCH_LOCATION_UNKNOWN,
  payload: isSearchLocationUnknown,
});

export const setShowAllSupplierTypes = (payload: ISearchState['showAllSupplierTypes']) => ({
  type: SearchActionTypes.CLICKED_SEE_ALL_SUPPLIERS,
  payload,
});

export const toggleSearchModal = (payload: ISearchState['searchModal']) => ({
  type: SearchActionTypes.CLICKED_SEARCH_MODAL,
  payload,
});

export const setPreset = (payload: ISearchState['preset']) => ({
  type: SearchActionTypes.SET_PRESET,
  payload,
});

export const forceToggleQuickFilter = (filterName: string) => ({
  type: SearchActionTypes.FORCE_TOGGLE_QUICK_FILTER,
  payload: { filterName },
});

//Interacted with Ad
export const interactedWithAdAnalytics = (
  payload: Partial<AdSearchResults['ads'][number]> & {
    actionType: string;
    adLocation: string;
  },
) => ({
  type: SearchActionTypes.INTERACTED_WITH_AD_ANALYTICS,
  payload,
});

export const setOnboardingFilters = (payload: ISearchState['onboardingFilters']) => ({
  type: SearchActionTypes.SET_ONBOARDING_FILTERS,
  payload,
});

export const setOnboardingFiltersSnackbarShown = (
  payload: ISearchState['onboardingFiltersSnackbarShown'],
) => ({
  type: SearchActionTypes.SET_ONBOARDING_FILTERS_SNACKBAR_SHOWN,
  payload,
});

export const setFiltersTab = (tab: TFiltersTab) => ({
  type: SearchActionTypes.SET_FILTERS_TAB,
  payload: tab,
});

export const setSortingOption = (sortingOption: SortOptionValue) => ({
  type: SearchActionTypes.SET_SORTING_OPTION,
  payload: sortingOption,
});
